<div *ngIf="graphData?.length > 0" class="buttons">
  <button class="btn btn-icon" smTooltip="Download" (click)="downloadImage()"><i class="al-icon al-ico-download"></i></button>
</div>
<sm-scatter-plot
  [xAxisType]="scalar ? 'linear' : 'category'"
  [xAxisLabel]="params?.[0] ?? $any(params)"
  [yAxisLabel]="metricName"
  [extraHoverInfoParams]="extraHoverInfoParams"
  [data]="graphData"
></sm-scatter-plot>
