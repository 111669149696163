<sm-compare-card-list
  [experiments]="experiments"
  [tasksLimitExceeded] = "experiments?.length && (taskIds$ | async)?.length > experiments?.length"
  [baseExperiment]="baseExperiment"
  [ngClass]="calculatingTree ? 'pending' : ''"
  (experimentListChanged)="syncUrl($event)"
  (toggled)="afterResize()"
>
  <div *compareCardHeader="let experiment; let i = index">
    <sm-experiment-compare-general-data
      [experiment]="experiment"
      [tags]="experimentTags[experiment.id]"
      (copyIdClicked)="copyIdToClipboard()">
    </sm-experiment-compare-general-data>
  </div>
  <div #treeCardBody class="tree-card-body" *compareCardBody="let experiment; let i = index">
    <div class="section h-100" *ngIf="experimentsDataControl[experiment.id]">
      <!--      TODO: move is-not-origin class outside nodes tree-->
      <ng-template let-dataSource="dataSource"
                   let-treeControl="treeControl"
                   [ngTemplateOutletContext]="{dataSource: experimentsDataControl[experiment.id][0],
                                               treeControl: experimentsDataControl[experiment.id][1]}"
                   [ngTemplateOutlet]="selfie" #selfie>
        <cdk-virtual-scroll-viewport
          #virtualScrollRef
          class="virtual-scroll-container"
          [class.is-not-origin]="i > 0"
          itemSize="28" minBufferPx="280" maxBufferPx="560"
        >
          <ng-container *cdkVirtualFor="let node of dataSource; let i = index">
            <div class="node" [class.parent]="node.hasChildren">
              <div *ngIf="node.hasChildren" class="section" [ngClass]="'depth-' + node.level"
                   (mouseenter)="rowHovered(node.data?.path)">
                <div class="content"
                     (click)="toggleNode(node)"
                     [ngClass]="node?.data?.classStyle"
                     [class.selected-diff]="checkIfSelectedPath(node.data)"
                     [class.hovered]="checkIfHoveredPath(node.data)"
                     [class.identical-row]="!allPaths[node.data.path]">
                  <i class="fas" [style.margin-left.px]="2 + node.level * 20"
                     [ngClass]="treeControl.isExpanded(node) ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
                  <span
                    class="title-key"
                    [smTooltip]="(renameMap[node.data.key] || node.data.key) | hideHashTitle"
                    [matTooltipShowDelay]="500"
                    smShowTooltipIfEllipsis
                    [class.ellipsis]="showEllipsis"
                    [style.width.px]="showEllipsis ? nativeWidth - 65 - node.level * 20 : null"
                  >{{(renameMap[node.data.key] || node.data.key) | hideHashTitle}}</span>
                  <i *ngIf="node.data.tooltip" class="al-icon sm al-ico-description node-icon" customClass="hyper-parameters-tooltip" [smTooltip]="node.data.tooltip"></i>
                </div>
              </div>
              <div *ngIf="!node.hasChildren" class="section" (click)="keyClicked(node.data)"
                   (mouseenter)="rowHovered(node.data?.path)">
                <div [style.padding-left.px]="2 + node.level * 20" [ngClass]="{
                  'node-item-container': true,
                  'identical-row': checkIfIdenticalRow(node.data),
                  'selected-diff': checkIfSelectedPath(node.data),
                  'hovered': checkIfHoveredPath(node.data),
                  'not-existing-on-origin': !node.data.existOnOrigin,
                  'not-existing-on-compared': !node.data.existOnCompared,
                  'diff-row': !node.data.isValueEqualToOrigin,
                  'equal-row': node.data.isValueEqualToOrigin,
                  'hide-identical-mode': hideIdenticalFields
                }">
                  <div>
                    <pre #row *ngIf="(node.data.value !== undefined) || (node.data.existOnOrigin && node.data.existOnCompared)"
                         [class.no-ellipsis]="(nativeWidth -2 > row.scrollWidth) && (row.scrollWidth === row.clientWidth)"
                         [class.with-ellipsis]="showEllipsis"
                         [style.width.px]="showEllipsis ? nativeWidth - 45 - node.level * 20 : null"
                    ><ng-container
                            *ngIf="!!node.data.value?.dataDictionary && !!node.data.value?.link; else simple">{{node.data.key |
                            hideHash}}<span
                            class="pointer link"
                            (click)="linkClicked(node.data.value.link)">{{node.data.value.dataValue}}</span></ng-container><ng-template
                            #simple>{{node.data.key | hideHash}}{{node.data.value}}</ng-template><i class="al-icon sm al-ico-line-expand extend-toggle" [class.fa-rotate-180]="!showEllipsis" (click)="toggleEllipsis(); $event.stopPropagation()"></i>
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </cdk-virtual-scroll-viewport>
      </ng-template>
    </div>
  </div>
</sm-compare-card-list>


<sm-portal outletId="searchDiff">
  <sm-search class="light-theme"
             [value]="searchText"
             [minimumChars]="1"
             [debounceTime]="300"
             [enableNavigation]="true"
             [searchCounterIndex]="foundIndex"
             [searchResultsCount]="foundPaths.length"
             (valueChanged)="find($event)"
  ></sm-search>
</sm-portal>

<sm-portal outletId="nextDiff">
  <button class="btn btn-secondary btn-arrow" smTooltip="Previous diff" (click)="goToNextDiff('up')">
    <i class="al-icon al-ico-arrow-up sm"></i>
  </button>

  <button class="btn btn-secondary btn-arrow" smTooltip="Next diff" (click)="goToNextDiff('down')">
    <i class="al-icon al-ico-arrow-down sm"></i>
  </button>
</sm-portal>
