<div class="list-container light-theme">
  @if (scatter) {
    <div class="metric-title">Plot axes</div>
    <div class="label">Y-axis</div>
    <sm-metric-variant-selector [title]="scatter? 'Metric' :'Select Performance Metric'" class="param-selector"
                                [selectedMetricVariants]="selectedMetric? [selectedMetric]: []"
                                [metricVariants]="metricsResults$ | async"
                                (selectMetricVariant)="metricVariantSelected($event)"
                                (clearSelection)="clearMetricsSelection()"
    ></sm-metric-variant-selector>
  } @else {
    <div class="metric-title">Coordinates</div>
    <sm-metric-variant-selector class="param-selector" [title]="'Performance Metrics'"
                                [selectedMetricVariants]="selectedMetrics" [multiSelect]="true" [skipValueType]="false"
                                [metricVariants]="metricsResults$ | async"
                                (selectMetricVariant)="multiMetricVariantSelected($event)"
                                (removeMetric)="multiMetricVariantSelected({addCol: false, variant: $event, valueType:$event.valueType})"
                                (clearSelection)="clearMetricsSelection()"
    ></sm-metric-variant-selector>
  }
  @if (scatter) {
    <div class="label">X-axis</div>
  }
  <sm-param-selector class="param-selector"
                     [itemsList]="hyperParams"
                     [title]="'Select Parameters'"
                     [selectedHyperParams]="selectedHyperParams"
                     [single]="scatter"
                     [selectFilteredItems]="selectHideIdenticalHyperParams$ | async"
                     [selectedItemsListMapper]="selectedItemsListMapper"
                     (selectedItems)="selectedParamsChanged($event)"
                     (clearSelection)="clearParamsSelection()"></sm-param-selector>
  @if (scatter) {
    <hr class="separate-margins">
    <div class="metric-title">Additional data point information</div>
    <sm-metric-variant-selector class="param-selector"
                                [title]="'Select Metrics'"
                                [selectedMetricVariants]="(selectedMetricsHoverInfo$ | async)"
                                [multiSelect]="true"
                                [skipValueType]="true"
                                [metricVariants]="metricsResults$ | async"
                                (selectMetricVariant)="metricVariantForHoverSelected($event)"
                                (removeMetric)="metricVariantForHoverSelected({addCol: false, variant: $event, valueType:null})"
                                (clearSelection)="clearMetricsSelectionForHover()"
    ></sm-metric-variant-selector>
    <sm-param-selector class="param-selector"
                       [title]="'Select Parameters'"
                       [itemsList]="hyperParams"
                       [selectedHyperParams]="selectedParamsHoverInfo$ | async"
                       [single]="false"
                       [selectFilteredItems]="selectHideIdenticalHyperParams$ | async"
                       [selectedItemsListMapper]="selectedItemsListMapper"
                       (selectedItems)="selectedParamsForHoverChanged($event)"
                       (clearSelection)="clearParamsForHoverSelection()"></sm-param-selector>
  }
</div>

<div class="graphs-container h-100">
  @if ((experiments$ | async).length > 1) {
    @if (scatter && !!selectedMetric && selectedHyperParams?.length > 0) {
      <sm-compare-scatter-plot [params]="selectedHyperParams"
                               [metric]="selectedMetric | metricVariantToPath: true"
                               [metricName]="selectedMetric | metricVariantToName: true"
                               [experiments]="experiments$ | async"
                               [extraHoverInfoParams]="selectedParamsHoverInfo$ |async"
                               [extraHoverInfoMetrics]="selectedMetricsHoverInfo$ | async"></sm-compare-scatter-plot>
    }
   @else if (!scatter && selectedMetrics.length > 0 && selectedHyperParams?.length > 0) {
      @if (plotlyReady$ | async) {
        <sm-parallel-coordinates-graph [experiments]="experiments$ | async"
                                       [metrics]="selectedMetrics"
                                       [parameters]="selectedHyperParams"
                                       (createEmbedCode)="createEmbedCode($event)"></sm-parallel-coordinates-graph>
      }
    } @else {
      <div class="d-flex align-items-center justify-content-center flex-column h-100 no-data">
        <div class="al-icon al-ico-no-data-graph"></div>
        <h4 class="no-data-title">NO DATA TO SHOW</h4>
        <div>Please select parameters & metrics</div>
      </div>
    }
  }
</div>
